import { render, staticRenderFns } from "./calculator-page.vue?vue&type=template&id=8f004c68&"
import script from "./calculator-page.vue?vue&type=script&lang=js&"
export * from "./calculator-page.vue?vue&type=script&lang=js&"
import style0 from "./calculator-page.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import KButton from '@web/components/core/k-button'
import KIcon from '@web/components/core/k-icon'
import KInput from '@web/components/core/k-input'
import KLayout from '@web/components/core/k-layout'
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {KButton,KIcon,KInput,KLayout,VExpandTransition,VFadeTransition,VSkeletonLoader})
